import React from 'react';
import Footer from './layout/Footer'

const App = () => {
    
    
    const styles = {
        body: {
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#dcd4d3e4', // Charcoal grey background
            color: '#f4f4f4', // Light text color for contrast
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            margin: 0,
            textAlign: 'center',
        },
        container: {
            background: '#444', // Slightly lighter grey for the container
            padding: '40px',
            borderRadius: '8px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
        },
        h1: {
            fontSize: '36px',
            marginBottom: '20px',
        },
        p: {
            fontSize: '18px',
            marginBottom: '30px',
        },
        contact: {
            fontSize: '16px',
            color: '#f4f4f4', // Light text color
        },
        footer: {
            marginTop: '20px',
            fontSize: '14px',
            color: '#bbb', // Lighter grey for footer text
        },
        link: {
            color: '#4a54de', // Link color
        },
    };

    return (
        <>
        <div style={styles.body}>
            <div style={styles.container}>
                <h1 style={styles.h1}>Website Under Development</h1>
                <p style={styles.p}>Thank you for your patience as we work on our website. We are excited to bring you a better experience!</p>
                <p style={styles.contact}>
                    For inquiries, please contact us at <a href="mailto:info@safarinetics.com" style={styles.link}>info@safarinetics.com</a>.
                </p>
                <div style={styles.footer}>
                    <p>&copy; 2024 Safari Netics. All rights reserved.</p>
                </div>
            </div>
        </div>

        <Footer/>

        </>
    );
};

export default App;
