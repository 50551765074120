import React, { useEffect, useState } from 'react';

const Footer = () => {

  const [newEmail, setNewEmail] = useState('')
  const [htmlContent, setHtmlContent] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    
    
    fetch('https://beshapp.frontend.safarinetics.com/emails/add/website', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ email: newEmail })
    })
    .then(response => {
      if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
      }
      
      // Check if the response is HTML
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("text/html")) {
          return response.text(); // Use text() for HTML responses
      } else {
          return response.json(); // Use json() for JSON responses
      }
  })
  .then(data => {
      if (typeof data === 'string') {
          // Set the HTML content to state
          setHtmlContent(data);
      } else {
          // Handle JSON response here
          setHtmlContent(data.success);
      }
  })
  .catch((error) => {
      console.error('Error:', error);
      // Handle error here (e.g., show an error message)
  })
}

useEffect(() => {
  const timer = setTimeout(() => {
      setHtmlContent('')
      setNewEmail('')
  }, 10000); // Delay in milliseconds (2000ms = 2 seconds)

  // Cleanup function to clear the timeout if the component unmounts
  return () => clearTimeout(timer);
}, [htmlContent]);


  return (
    <footer className="bg-dark text-white py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5 className="mb-4">Safari Netics Info</h5>
            <p className="text-light">Safari Netics is a leading provider of software hosting solutions, dedicated to serving the safari industry.</p>
            <ul className="list-unstyled">
              <li><a href="#about" className="text-light">About Us</a></li>
              <li><a href="#services" className="text-light">Services</a></li>
              <li><a href="#contact" className="text-light">Contact Us</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className="mb-4">Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#home" className="text-light">Home</a></li>
              <li><a href="#features" className="text-light">Features</a></li>
              <li><a href="#pricing" className="text-light">Pricing</a></li>
              <li><a href="#faq" className="text-light">FAQ</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5 className="mb-4">Connect with Us</h5>
            <p className="text-light">Follow us on social media for updates and special offers.</p>
            <ul className="list-inline">
              <li className="list-inline-item"><a href="#facebook" className="text-light"><i className="fab fa-facebook-f"></i></a></li>
              <li className="list-inline-item"><a href="#twitter" className="text-light"><i className="fab fa-twitter"></i></a></li>
              <li className="list-inline-item"><a href="#linkedin" className="text-light"><i className="fab fa-linkedin"></i></a></li>
              <li className="list-inline-item"><a href="#instagram" className="text-light"><i className="fab fa-instagram"></i></a></li>
            </ul>
            <div className="mt-3">
              <h5 className="mb-3 text-light">Newsletter</h5>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                   value={newEmail}
                   onChange={(e) => setNewEmail(e.target.value)}
                   type="email" className="form-control" placeholder="Enter your email" aria-label="Email" aria-describedby="button-addon1" />
                  <button className="btn btn-primary" type="submit" id="button-addon2">Subscribe</button>
                </div>
              </form>
              {htmlContent && (
              <div id="responseContainer" className=' alert alert-info text-success ' dangerouslySetInnerHTML={{ __html: htmlContent }} />
              )}
            </div>
          </div>
        </div>
        {/* <hr className="my-4 " /> */}
        <div className="row pt-4 mt-4" style={{ borderTop: '2px solid #d4af37' }} >
          <div className="col-md-6">
            <p className="small text-info">&copy; {new Date().getFullYear()} Safari Netics. All rights reserved.</p>
          </div>
          <div className="col-md-6">
            <ul className="list-inline text-md-end">
              <li className="list-inline-item"><a href="#terms" className="text-light">Terms of Use</a></li>
              <li className="list-inline-item"><a href="#privacy" className="text-light">Privacy Policy</a></li>
              <li className="list-inline-item"><a href="#cookies" className="text-light">Cookie Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
